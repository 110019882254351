<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/board_handling_system/otherss/ng_reject_conveyor"
          >
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%"
                class="card-img-top img-fluid"
                src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/otherss/NG Reject Conveyor.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  NG Reject Conveyor
                </h4>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/board_handling_system/otherss/manual_insertion_conveyor"
          >
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%"
                class="card-img-top img-fluid"
                src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/otherss/Manual Insertion Conveyor.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  Manual Insertion Conveyor
                </h4>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/board_handling_system/otherss/pass_thru_type_auto_loader"
          >
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%"
                class="card-img-top img-fluid"
                src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/otherss/PASS THRU TYPE AUTO LOADER.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  PASS THRU TYPE AUTO LOADER
                </h4>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/board_handling_system/otherss/national_prorack"
          >
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%"
                class="card-img-top img-fluid"
                src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/otherss/NATIONAL PRORACK.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  NATIONAL PRORACK
                </h4>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/board_handling_system/otherss/nikko_rack"
          >
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%"
                class="card-img-top img-fluid"
                src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/otherss/NIKKO RACK.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  NIKKO RACK
                </h4>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../../components/Header.vue";

import Footer from "../../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>